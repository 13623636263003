.confirm-section {
  font-size: 1.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media (max-width: 425px) {
    font-size: 1.2rem;
  }

  .confirm-btns {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (max-width: 768px) {
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    img {
      margin-right: 0.5rem;
    }
  }

  .container-img {
    background: url('../../assets/img/bg_sully_doors.jpg') var(--dark-purple) repeat-x center bottom;
    font-size: 1.5rem;
    margin-top: 2rem;
    padding: 2rem 0 9rem;

    img {
      width: 25%;
    }

    @media (max-width: 768px) {
      background-position: left bottom;

      img {
        width: 30%;
      }
    }
  }
}


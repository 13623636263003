.family-section {
  background: url('../../assets/img/bg_boo_doors.jpg') var(--dark-purple) repeat-x center bottom;
  font-size: 1.5rem;
  padding: 20px 20px 165px;

  @media (max-width: 768px) {
    background-position: left bottom;
    padding-bottom: 175px;
  }

  @media (max-width: 425px) {
    gap: 1rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .persons-container {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: 768px) {
      gap: 1rem;
    }

    @media (max-width: 425px) {
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  p {
    position: relative;
    z-index: 1;

    &.name {
      font-size: 3rem;
      letter-spacing: 0.5rem;
      line-height: 2rem;
    }

    @media (max-width: 768px) {
      &.name {
        font-size: 2rem;
        letter-spacing: 0.4rem;
      }
    }

    @media (max-width: 425px) {
      font-size: 1.2rem;

      &.name {
        font-size: 2.4rem;
      }
    }
  }

  img {
    margin: auto;
    width: 8rem;
  }

  .birthday-hat {
    position: absolute;
    left: -25px;
    rotate: -30deg;
    top: -58px;
    width: 65px;
  }

  .monster {
    font-size: 40px;
    margin-bottom: 0;
  }
}
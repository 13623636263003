@keyframes flip {
  0%,80% {
    transform: rotateY(360deg);
  }
}

@keyframes jump {
  0%, 40%, 100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 var(--hover); }
}

@keyframes zoomInDown {
  0% {
      opacity: 0;
      -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
      transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
      -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
      animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }
  60% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
      transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
      -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
      animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

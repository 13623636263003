*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  text-align: center;
  text-size-adjust: none;
  text-shadow: 0 1px 2px rgba(24, 24, 24, 0.6);
  user-select: none;
}

html, body {
  background-color: var(--light-blue);
  color: var(--white);
  font-family: var(--handle);
  font-size: 1rem;
	margin: 0;
  min-height: 100vh;
	padding: 0;
  position: relative;
  text-align: center;
  text-shadow: 0 1px 2px rgba(24, 24, 24, 0.6);

  @media (max-width: 425px) {
    background-size: initial;
  }
}

.main {
  height: 100vh;
  width: 100%;

  &.with-bg {
    background-image: url('../../assets/img/bg_sully.jpg');
    background-position: center top;
    background-size: 100%;
  }
}

a {
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: var(--light-purple);
  }
}

u {
  background-color: var(--yellow);
  text-decoration: none;
}

.img-circle {
  background-color: var(--white);
  border: 0.3rem solid var(--pink);
  border-radius: 50%;
  height: 12rem;
  width: 12rem;

  @media (max-width: 425px) {
    height: 10rem;
    width: 10rem;
  }
}

button {
  border: 0;
  border-radius: 8px;
  font-family: var(--monster);
  font-size: 1.5rem;
  outline: 0;
  text-shadow: 0 1px 2px rgba(24, 24, 24, 0.6);
  transition: 0.25s;

  img {
    height: 1rem;
    width: 1rem;
  }

  &:active {
    border: 0;
  }

  &.btn-blue {
    background-color: var(--light-blue);
    color: var(--white);
  }

  &.btn-pink {
    background-color: var(--pink);
    color: var(--white);
  }

  &.btn-purple {
    background-color: var(--light-purple);
    color: var(--white);
  }

  // Animation
  &.close {
    &:active,
    &:hover,
    &:focus {
      box-shadow:
        inset -3.5em 0 0 0 var(--hover),
        inset 3.5em 0 0 0 var(--hover);
    }
  }

  &.fill {
    &:active,
    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 2em var(--hover);
    }
  }

  &.pulse {
    &:active,
    &:hover,
    &:focus {
      animation: pulse 1s;
      box-shadow: 0 0 0 2em transparent;
    }
  }

  &.slide {
    &:active,
    &:hover,
    &:focus {
      box-shadow: inset 6.5em 0 0 0 var(--hover);
    }
  }
}

$colors: (
  fill: #6D4D85,
  pulse: #6D4D85,
  close: #6D4D85,
  slide: #6D4D85,
);

@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{adjust-hue($color, 45deg)};
  }
}
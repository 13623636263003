.intro-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: calc(50% - 40px);
  transform: translate(-50%, -50%) !important;

  img {
    width: 90%;
  }

  @media (max-width: 1024px) {
    img {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    img {
      width: 120%;
    }
  }

  @media (max-width: 425px) {
    img {
      width: 160%;
    }
  }

  button {
    font-family: var(--monster);
    width: 200px;
  }
}


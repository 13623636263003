.video-section {
  background: url('../../assets/img/bg_borders.jpeg');
  background-size: cover;
  color: var(--dark-gray);
  padding: 1rem;

  video {
    border-radius: 0.8rem;
    height: 20rem;
    width: 40%;

    @media (max-width: 768px) {
      height: 250px;
      width: 40%;
    }
  }

  @media (max-width: 425px) {
    padding-top: 1.5rem;

    video {
      height: 250px;
      width: 90%;
    }
  }
}



.presents-section {
  background: url('../../assets/img/bg_all.jpg') var(--orange) repeat-x center bottom;
  font-size: 1.5rem;
  padding: 1rem 1rem 10rem;

  @media (max-width: 768px) {
    background-position: left bottom;
    padding-bottom: 8rem;
  }

  @media (max-width: 425px) {
    font-size: 1.2rem;
  }

  .info-container {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (max-width: 425px) {
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    a {
      &:active div,
      &:focus div,
      &:hover div {
        background-color: var(--light-gray);
      }
    }
  }
}
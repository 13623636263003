.home-section {
  padding-top: 1rem;

  h1 {
    font-size: 3.2rem;
    margin-bottom: -15px;
  }

  @media (max-width: 425px) {
    img {
      width: 97%;
    }

    h1 {
      font-size: 2.5rem;
    }
  }
}


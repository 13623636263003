.card {
  background-color: var(--white);
  color: var(--dark-gray);
  font-size: 1rem;
  margin: 0 0.5rem;
  padding: 1rem;
  position: relative;
  text-align: left;
  text-shadow: none;

  &.balloon-sully {
    background: url('../../assets/img/sully_balloon.png') var(--white) no-repeat 80% 80%;
    background-size: 30%;

    @media (max-width: 768px) {
      background-position: 95% 80%;
      background-size: 35%;
    }

    @media (max-width: 425px) {
      background-position: center 85%;
      background-size: 60%;
    }
  }

  &.btns {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.3rem;
    margin: 0 1rem;
    text-decoration: none;

    img {
      margin-right: 1rem;
      width: 5rem;
    }
  }
}

:root {
  --dark-blue: #01142d;
  --dark-gray: #181818;
  --dark-purple: #6D4D85;
  --light-blue: #0CC0DF;
  --light-gray: #f9f4f4;
  --light-purple: #CB6CE6;
  --green: #A7CA20;
  --orange: #FF914D;
  --pink: #FF66C4;
  --white: #FFFFFF;
  --yellow: #FFDE59;
}

.dark-blue {
  color: var(--dark-blue);
}

.dark-gray {
  color: var(--dark-gray);
}

.dark-purple {
  color: var(--dark-purple);
}

.light-blue {
  color: var(--light-blue);
}

.light-purple {
  color: var(--light-purple);
}

.green {
  color: var(--green);
}

.orange {
  color: var(--orange);
}

.pink {
  color: var(--pink);
}

.yellow {
  color: var(--yellow);
}
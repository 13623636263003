@font-face {
  font-family: 'Cherry Bomb';
  src: url('../../assets/fonts/CherryBombOne.woff') format('woff'),
      url('../../assets/fonts/CherryBombOne.ttf') format('truetype');
}

@font-face {
  font-family: 'Handle';
  src: url('../../assets/fonts/Handlee-Regular.woff') format('woff'),
      url('../../assets/fonts/Handlee-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Monster AG';
  src: url('../../assets/fonts/MonsterAG.woff') format('woff'),
      url('../../assets/fonts/MonsterAG.ttf') format('truetype');
}

:root {
  --cherry: 'Cherry Bomb', sans-serif;
  --handle: 'Handle', sans-serif;
  --monster: 'Monster AG', sans-serif;
}

.title {
  font-size: 2.5rem;

  @media (max-width: 425px) {
    font-size: 2rem;
  }
}

.small {
  font-size: 1rem;
}

.cherry {
  font-family: var(--cherry);
}

.handle {
  font-family: var(--handle);
}

.monster {
  font-family: var(--monster);
  letter-spacing: 1rem;
}
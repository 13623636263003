.address-section {
  background: url('../../assets/img/bg_mike_doors.jpg') var(--orange) repeat-x center bottom;
  padding: 1rem 1rem 10rem;

  @media (max-width: 768px) {
    background-position: right bottom;
    padding-bottom: 10.5rem;
  }

  h3 {
    font-size: 2.5rem;
    letter-spacing: 0.3rem;

    @media (max-width: 425px) {
      font-size: 2.1rem;
      letter-spacing: 0.2rem;
    }
  }

  .img-border {
    border-radius: 0.8rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .info {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (max-width: 425px) {
      gap: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

.countdown-section {
  padding-bottom: 1rem;

  .time {
    display: inline-block;
    margin: 0.5rem;

    > p {
      animation: fade 3s;
      font-size: 3rem;
      line-height: 2.5rem;
      margin: 0;
    }

    > .sp {
      font-size: 1.8rem;
    }

    @media (max-width: 425px) {
      > p {
        font-size: 1.9rem;
      }

      > .sp {
        font-size: 1.2rem;
      }
    }
  }
}


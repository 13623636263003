.jump-letter {
  position: relative;
  text-shadow: 0 0 3px var(--dark-purple), 0 0 5px var(--dark-blue);
  -webkit-box-reflect: below 0 linear-gradient(transparent, rgba(0, 74, 173, 0.2));

  span {
    animation: jump 1s infinite;
    animation-delay: calc(.1s * var(--i));
    font-family: var(--monster);
    font-size: 8rem;
    position: relative;
    display: inline-block;
    text-transform: uppercase;

    @media (max-width: 425px) {
      font-size: 6rem;
    }
  }

  img {
    animation: jump 1s infinite;
    animation-delay: calc(.1s * var(--i));
    position: relative;
    display: inline-block;
  }
}

.flip-letter {
  position: relative;

  span {
    animation: flip 2s infinite;
    animation-delay: calc(.2s * var(--i));
    display: inline-block;
    margin: 0 0.2rem;
    position: relative;

    @media (max-width: 425px) {
      margin: 0 0.1rem;
    }
  }
}
